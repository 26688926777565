
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as indexPA1jtQSyzaMeta } from "/builds/mis/medflex_landing/pages/index.vue?macro=true";
import { default as mis0WrQfGgWkgMeta } from "/builds/mis/medflex_landing/pages/mis.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/mis/medflex_landing/pages/index.vue")
  },
  {
    name: "mis",
    path: "/mis",
    component: () => import("/builds/mis/medflex_landing/pages/mis.vue")
  }
]