<template>
  <section>
    <v-container>
      <div class="mb-6 mb-md-10">
        <h2 :class="['text-center text-h4']">
          <slot name="title">{{ title }}</slot>
        </h2>
        <p
          class="mt-2 text-body-2 mt-md-4 text-md-body-1"
          :class="['text-center']"
        >
          <slot name="subtitle">{{ subtitle }}</slot>
        </p>
      </div>
      <slot />
    </v-container>
  </section>
</template>

<script lang="ts">
export default defineComponent({
  name: 'HomeSection',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    }
  }
})
</script>
